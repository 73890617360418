import React from 'react'
import './App.css';
import RouterView from './router'
export default function App() {
  return (
    <div className='home'>
      <RouterView></RouterView>
    </div>
  )
}
