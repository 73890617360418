import React, { useEffect, useState } from 'react';
import { Col, Row, Input, Tag } from 'antd';
const { TextArea } = Input;

const View = (props) => {
    const [value01, setValue01] = useState('');
    const [htmlContent, setHtmlContent] = useState('');

    const maxlength = 3500;

    const showTag = (val) => {
        if (props.loading) {
            return <></>
        }

        if (val > 80) {
            return <Tag color="red">{val} %</Tag>
        }
        if (val > 60) {
            return <Tag color="yellow">{val} %</Tag>
        }
        if (val > 40) {
            return <Tag color="blue">{val} %</Tag>
        }
        return <Tag color="green">{val} %</Tag>
    }

    // const tempHtml = '<p style="min-height: 180px; max-height: 200px; overflow: auto; padding: 0px; margin: 0px; border: 1px dashed rgb(204, 204, 204);">'
    //     + 'cest asdfad sldf '
    //     + '</p>'
    const checkValue = () => {
        console.log("checkValue temp");
        // var temp = value01;
        var temp = props.value;
        var reDate = '[{"InsertStart":45,"InsertCount":2},{"InsertStart":38,"InsertCount":4}]'
        reDate = props.diffs
        var data = JSON.parse(reDate)
        data.forEach(person => {
            console.log(person.InsertStart, person.InsertCount);
            var position = person.InsertStart + person.InsertCount; // 要插入的位置
            temp = temp.slice(0, position) + '</span>' + temp.slice(position)

            position = person.InsertStart; // 要插入的位置
            temp = temp.slice(0, position) + '<span style="color: rgb(0, 0, 0);">' + temp.slice(position)

        });

        temp = '<p style="min-height: 180px; color: rgb(255, 85, 0);max-height: 200px; overflow: auto; padding: 0px; margin: 0px; border: 1px dashed rgb(204, 204, 204);">'
            + temp
            + '</p>'
        console.log(temp);
        setHtmlContent(temp);
        return temp;

    }

    const customStyle = {
        marginBottom: '30px'
    };

    useEffect(
        () => {
            debugger;

            console.log("useEffect");
            checkValue();

            if (props.msgId.startsWith("clear_")) {
                setValue01("");
            }
        },
        [props.msgId]
    )

    return <Row>
        <Col xs={3} sm={3} md={3} lg={1} xl={1} offset={1} >
            {props.title01}
            <br />
            {showTag(props.rate)}
        </Col>
        <Col xs={19} sm={19} md={19} lg={9} xl={9}>
            <TextArea style={customStyle} showCount maxLength={maxlength} rows={8} value={value01}
                onChange={(e) => {
                    setValue01(e.target.value);
                    props.onValueChange(e.target.value);
                }}
            />
        </Col>
        <Col xs={3} sm={3} md={3} lg={1} xl={1} offset={1} >
            {props.title02}
            <br />
        </Col>
        <Col xs={19} sm={19} md={19} lg={9} xl={9}>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            {/* <p style={{
                minHeight: '180px',
                maxHeight: '200px',
                overflow: 'auto',
                padding: '0',
                margin: '0',
                border: '1px solid #ccc'
            }}>{checkValue()}</p> */}
        </Col>
    </Row>
}

export default View