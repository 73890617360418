import '../App.css';

import '@chatui/core/es/styles/index.less';
// 引入组件
import Chat, { Bubble, useMessages, Modal, Input, toast, Radio, RadioGroup, Popup, Image, Icon } from '@chatui/core';
// 引入样式
import '@chatui/core/dist/index.css';
import axios from "axios";
import { nanoid } from "nanoid";

// import { Link } from 'react-router-dom'
import { useState } from 'react';

//const apiUrl = 'https://localhost:7291/api/ChatProcess';
 const apiUrl='https://gptapi.tyinvoice.cn/api/ChatProcess';

const initialMessages = [
  {
    type: 'text',
    content: { text: '主人好，我是基于chatGPT的智能助理，可以回答问题、翻译、写作等，欢迎随时与我交流~' },
    user: { avatar: './ChatGPT_logo.png' },
  },
  // {
  //   type: 'image',
  //   content: {
  //     picUrl: '//img.alicdn.com/tfs/TB1p_nirYr1gK0jSZR0XXbP8XXa-300-300.png',
  //   },
  //   user: { avatar: './ChatGPT_logo.png' },
  // },
];

// 默认快捷短语，可选
const defaultQuickReplies = [
  {
    icon: "keyboard-circle",
    name: '奇思妙想',
    isNew: true
  },
  {
    name: '你有意识吗',
  },
  {
    name: '从1加到100等于多少',
  },
  {
    name: '推荐一部科幻电影',
  },
];

const options = [
  // { label: 'openAI官方', value: 'https://api.openai.com' },
  { label: '代理A', value: 'D01' },
  { label: '代理B', value: 'D02' },
  { label: '代理C', value: 'D03', disabled: true },
];

export default function () {
  // 消息列表
  const { messages, appendMsg, updateMsg, setTyping } = useMessages(initialMessages);
  // 输入Key
  const [open, setOpen] = useState(false);
  var [keyValue, setValue1] = useState(localStorage.getItem('apiKey') || "");
  function handleSetValue(val) {
    setValue1(val)
    keyValue = localStorage.getItem('apiKey');
  }

  // 请求URL
  // var [baseUrl, setValue] = useState("https://open.aiproxy.xyz");
  var [dailiType, setDailiType] = useState(localStorage.getItem('dailiType') || "D01");
  var [hotList, sethotList] = useState([]);
  function handleChange(val) {
    setDailiType(val);
  }
  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const [openPop, setOpenPop] = useState(false);

  function handleOpenPop() {
    setOpenPop(true);
  }

  function handleClosePop() {
    setOpenPop(false);
  }
  function handleConfirm() {

    // console.log(2, baseUrl);
    // 将输入信息存储本地
    localStorage.setItem('dailiType', dailiType);
    toast.success("操作成功")
    setOpen(false);
  }

  // 判断红点
  if (localStorage.getItem('clickFlag')) {
    defaultQuickReplies[0].isNew = false
  } else {
    defaultQuickReplies[0].isNew = true
  }

  const [chatMessage, setChatMessage] = useState([]);
  // 知乎热榜
  function zhihuSend() {
    // 调用第三方热榜接口
    axios.get('https://api.oioweb.cn/api/common/HotList')
      .then((res) => {
        console.log(res.data.result.知乎);
        sethotList(res.data.result.知乎.slice(0, 20))
      })
      .catch((err) => {
        console.log(err)
        toast.fail("出错啦！请稍后再试")
        handleClosePop()
      })
    // function handleData(data) {
    //   // 处理数据
    //   console.log(123);
    // }

    // const url = '/api/v3/feed/topstory/hot-lists/total?limit=50&desktop=true&callback=handleData';
    // const script = document.createElement('script');
    // script.src = url;
    // document.body.appendChild(script);

  }
  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

  // 发送回调
  const handleSend = async (type, val) => {
    // var apiKey = localStorage.getItem('apiKey')
    // if (!apiKey) {
    //   // toast.fail("请先输入你的apiKey")
    //   setOpen(true);
    //   return
    // }




    // var msgID=uuid();
    // setTimeout(async () => {
    //   debugger
    //   var temp = "";
    //   for (var i = 0; i < 500; ++i) {
    //     await sleep(20);

    //     temp += i + " ";
    //     if (i == 0) {
    //       appendMsg({
    //         _id: msgID,
    //         type: 'text',
    //         content: { text: temp },
    //         user: { avatar: './ChatGPT_logo.png' },
    //       });

    //     } else {
    //       updateMsg(
    //         msgID,
    //         {
    //           type: 'text',
    //           content: { text: temp },
    //           user: { avatar: './ChatGPT_logo.png' },
    //         });
    //     }
    //   }
    // }, 1000);


    // return;

    if (type === 'text' && val.trim()) {
      // TODO: 发送请求
      appendMsg({
        type: 'text',
        content: { text: val },
        position: 'right',
      });

      setTyping(true);

      let controller = new AbortController()
      try {

        const response = await fetch(apiUrl, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify({
            content: val
          }),
          signal: controller.signal
        })

        const reader = response.body.getReader()

        var msgID = nanoid();
        appendMsg({
          _id: msgID,
          type: 'text',
          content: { text: "..." },
          user: { avatar: './ChatGPT_logo.png' },
        });


        let data = ''
        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          }
          data += new TextDecoder().decode(value)
          // setData(data)
          // console.log(data)

          updateMsg(
            msgID,
            {
              type: 'text',
              content: { text: data },
              user: { avatar: './ChatGPT_logo.png' },
            });
        }
      } catch {
        console.log("请求失败")
      }




      // axios
      //   .post(
      //     // 'https://api.openai.com/v1/chat/completions',
      //     // baseUrl + '/v1/chat/completions', // 基本地址
      //     //'https://localhost:7291/api/ChatProcess', // 基本地址
      //     //'https://gptapi.tyinvoice.cn/api/ChatProcess', // 基本地址
      //      apiUrl
      //     {
      //       content: val
      //     },
      //     {
      //       headers: {
      //         'Content-Type': 'application/json',
      //         // Authorization: 'Bearer ' + apiKey,
      //       },
      //     },
      //   )
      //   .then(async (res) => {
      //     var msgID = uuid();

      //     const response = res.data.trim();
      //     const newMessages = [
      //       ...chatMessage,
      //       { content: val, role: 'user' },
      //       { content: response, role: 'system' },
      //     ];
      //     // setChatMessage(newMessages as any);
      //     setChatMessage(newMessages);
      //     appendMsg({
      //       _id: msgID,
      //       type: 'text',
      //       content: { text: response },
      //       user: { avatar: './ChatGPT_logo.png' },
      //     });

      //     await sleep(500);

      //     var temp = "";
      //     for (var i = 0; i < 500; ++i) {
      //       await sleep(20);

      //       temp += i + " ";
      //       updateMsg(
      //         msgID,
      //         {
      //           type: 'text',
      //           content: { text: temp },
      //           user: { avatar: './ChatGPT_logo.png' },
      //         });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //     toast.fail("出错啦！请稍后再试")
      //   }
      //   );

    }
  }

  // 快捷短语回调，可根据 item 数据做出不同的操作，这里以发送文本消息为例
  function handleQuickReplyClick(item) {
    if (item.name == "奇思妙想") {
      defaultQuickReplies[0].isNew = false
      localStorage.setItem('clickFlag', false)
      zhihuSend()
      handleOpenPop()
      return
    }
    // if (item.name == "下载APP") {
    //   window.location.href = "https://gitee.com/gfh_he/chat-robot/releases"
    //   return
    // }
    // if (item.name == "前端笔记") {
    //   window.location = "https://gfh_he.gitee.io/vue-note/"
    //   return
    // }
    handleSend('text', item.name);
  }

  function handleHot(type, target) {
    setOpenPop(false);
    // var content = target.excerpt + "  " +target.title
    handleSend(type, target.title);
  }

  function renderMessageContent(msg) {
    const { type, content } = msg;

    // 根据消息类型来渲染
    switch (type) {
      case 'text':
        return <Bubble content={content.text} />;
      case 'image':
        return (
          <Bubble type="image">
            <img src={content.picUrl} alt="" />
          </Bubble>
        );
      default:
        return null;
    }
  }

  // 打开模态框
  function setOption() {
    // setValue("")
    handleOpen()
  }

  function clear() {
    window.location.reload();
  }

  function uuid() {
    var uuidValue = "", k, randomValue;
    for (k = 0; k < 32; k++) {
      randomValue = Math.random() * 16 | 0;

      if (k == 8 || k == 12 || k == 16 || k == 20) {
        uuidValue += "-"
      }
      uuidValue += (k == 12 ? 4 : (k == 16 ? (randomValue & 3 | 8) : randomValue)).toString(16);
    }
    return uuidValue;
  }

  return (
    [
      <div className='head'>
        <div className='head-set' onClick={() => setOption()}><img src={process.env.PUBLIC_URL + '/set.png'} alt="设置" /></div>
        <div className='head-title'>chatBot@好看</div>
        <div className='head-clear' onClick={() => clear()}><img src={process.env.PUBLIC_URL + '/clear.png'} alt="清空" /></div>
      </div>,
      <Chat
        // navbar={{ title: 'chatBot' }}
        placeholder="有问题尽管问我~"
        messages={messages}
        renderMessageContent={renderMessageContent}
        quickReplies={defaultQuickReplies}
        onQuickReplyClick={handleQuickReplyClick}
        onSend={handleSend}
      />,
      <Modal
        active={open}
        title="openAI"
        showClose={false}
        onClose={handleClose}
        actions={[
          {
            label: '确认',
            color: 'primary',
            onClick: handleConfirm,
          },
          // {
          //   label: '取消',
          //   onClick: handleClose,
          // },
        ]}
      >
        <div>
          {/* <p><span className='requird-span'>*</span>API Key</p>
          <Input value={keyValue} onChange={val => handleSetValue(val)} placeholder="请输入API Key" /> */}
          <p><span className='requird-span'>*</span>API Server</p>
          {/* 单选 */}
          <RadioGroup value={dailiType} options={options} onChange={handleChange} />
        </div>
      </Modal>,
      <div>
        <Popup
          active={openPop}
          title="选择以下话题 快速与我对话"
          onClose={handleClosePop}
        >
          <div className='pop-outer'>
            <h3>知乎热榜</h3>
            <div className='zhihu-list'>
              {/* 
                1.在列表遍历时一定要绑定key
                2.key值必须绑定在遍历的直接子元素上
                3.key的做用:a:唯一标识 b.对元素进行添加,修改或者删除的唯一标识
            */}
              {hotList.map(item => {
                return <div className='zhihu-item' onClick={() => handleHot('text', item)}><div key={item.index}>{item.title}</div><Icon className='search-icon' type="chevron-right" /></div>
              })}
            </div>
          </div>
        </Popup>
      </div>

    ]
  );
}