
import React, { useState } from 'react';
import { Button, Flex } from 'antd';
import { Col, Row, Input, Skeleton, Tag } from 'antd';
const { TextArea } = Input;

// const apiUrl = 'https://localhost:7291/api/Compare';
const apiUrl = 'https://gptapi.tyinvoice.cn/api/Compare/Post';

function Compare() {

    const [loading, setLoading] = useState(true);
    const [value00, setValue00] = useState('');
    const [value01, setValue01] = useState('');
    const [value02, setValue02] = useState('');
    const [value03, setValue03] = useState('');
    const [value04, setValue04] = useState('');
    const [value05, setValue05] = useState('');
    const [value06, setValue06] = useState('');
    const [result, setResult] = useState([0, 0, 0, 0, 0, 0]);
    const maxlength = 3500;


    const customStyle = {
        marginBottom: '30px'
    };

    const dohttpPost = async () => {


        // axios.post(apiUrl, [value00, value01, value02, value03, value04, value05, value06])
        //     .then((res) => {
        //         debugger
        //         console.log("对比结果为：")
        //         console.log(res)
        //     })
        //     .catch((err) => {
        //         debugger
        //         console.log("对比结果为：")
        //         console.log(err)
        //     })

        let controller = new AbortController()
        try {

            const response = await fetch(apiUrl, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(
                    {
                        contents: [value00, value01, value02, value03, value04, value05, value06]
                    }

                ),
                signal: controller.signal
            })

            const reader = response.body.getReader()


            let data = ''
            while (true) {
                const { done, value } = await reader.read()
                if (done) {
                    break
                }
                setLoading(false);

                data += new TextDecoder().decode(value)
                // setData(data)
                console.log(data)

                var strArray = data.split(","); // 将字符串按逗号分隔成子字符串
                var numArray = strArray.map(Number); // 将子字符串数组转换为数字数组
                setResult(numArray);
            }
        } catch (error) {
            console.log("请求失败:")
            console.error('An error occurred:', error);
        }
    }

    const showTag = (val) => {

        if (loading) {
            return <></>
        }

        if (val > 80) {
            return <Tag color="red">{val}</Tag>
        }
        if (val > 60) {
            return <Tag color="yellow">{val}</Tag>
        }
        if (val > 40) {
            return <Tag color="blue">{val}</Tag>
        }
        return <Tag color="green">{val}</Tag>

    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // height: '100vh',
            minHeight: '100vh'
        }}>
            <div style={{
                width: '100%',
                padding: '20px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
            }}>

                <Row>
                    <Col xs={3} sm={3} md={3} lg={1} xl={1} offset={1} >
                    </Col>
                    <Col xs={19} sm={19} md={19} lg={9} xl={9} >
                        <Flex gap="small" wrap="wrap">
                            <Button type="primary" onClick={() => {
                                setLoading(true);
                                dohttpPost();
                            }}>开始对比</Button>
                            <Button onClick={() => {
                                setLoading(true);
                                setValue00("");
                                setValue01("");
                                setValue02("");
                                setValue03("");
                                setValue04("");
                                setValue05("");
                                setValue06("");
                            }}>清空所有</Button>
                            {/* <Button type="link">去提问</Button> */}

                        </Flex>
                        {
                            !loading && <>
                                <p>核对01 相似度为:&nbsp; {showTag(result[0])} %</p>
                                <p>核对02 相似度为:&nbsp; {showTag(result[1])} %</p>
                                <p>核对03 相似度为:&nbsp; {showTag(result[2])} %</p>
                                <p>核对04 相似度为:&nbsp; {showTag(result[3])} %</p>
                                <p>核对05 相似度为:&nbsp; {showTag(result[4])} %</p>
                                <p>核对06 相似度为:&nbsp; {showTag(result[5])} %</p>
                            </>
                        }
                        <Skeleton active loading={loading} />
                    </Col>

                    <Col xs={3} sm={3} md={3} lg={1} xl={1} offset={1} >
                        原始文本
                    </Col>
                    <Col xs={19} sm={19} md={19} lg={9} xl={9}>
                        <TextArea style={customStyle} showCount maxLength={maxlength} rows={11} value={value00}
                            onChange={(e) => setValue00(e.target.value)} />
                    </Col>

                </Row>
                <Row>
                    <Col xs={3} sm={3} md={3} lg={1} xl={1} offset={1} >
                        核对01
                        <br />
                        {showTag(result[0])}
                    </Col>
                    <Col xs={19} sm={19} md={19} lg={9} xl={9}>
                        <TextArea style={customStyle} showCount maxLength={maxlength} rows={8} value={value01}
                            onChange={(e) => setValue01(e.target.value)} />
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={1} xl={1} offset={1} >
                        核对02
                        <br />
                        {showTag(result[1])}
                    </Col>
                    <Col xs={19} sm={19} md={19} lg={9} xl={9}>
                        <TextArea style={customStyle} showCount maxLength={maxlength} rows={8} value={value02}
                            onChange={(e) => setValue02(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={3} sm={3} md={3} lg={1} xl={1} offset={1} >
                        核对03
                        <br />
                        {showTag(result[2])}
                    </Col>
                    <Col xs={19} sm={19} md={19} lg={9} xl={9}>
                        <TextArea style={customStyle} showCount maxLength={maxlength} rows={8} value={value03}
                            onChange={(e) => setValue03(e.target.value)} />
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={1} xl={1} offset={1} >
                        核对04
                        <br />
                        {showTag(result[3])}
                    </Col>
                    <Col xs={19} sm={19} md={19} lg={9} xl={9}>
                        <TextArea style={customStyle} showCount maxLength={maxlength} rows={8} value={value04}
                            onChange={(e) => setValue04(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={3} sm={3} md={3} lg={1} xl={1} offset={1} >
                        核对05
                        <br />
                        {showTag(result[4])}
                    </Col>
                    <Col xs={19} sm={19} md={19} lg={9} xl={9}>
                        <TextArea style={customStyle} showCount maxLength={maxlength} rows={8} value={value05}
                            onChange={(e) => setValue05(e.target.value)} />
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={1} xl={1} offset={1} >
                        核对06
                        <br />
                        {showTag(result[5])}
                    </Col>
                    <Col xs={19} sm={19} md={19} lg={9} xl={9}>
                        <TextArea style={customStyle} showCount maxLength={maxlength} rows={8} value={value06}
                            onChange={(e) => setValue06(e.target.value)} />
                    </Col>
                </Row>
                {/* 
{/* 
            <h3>
                Compare
            </h3> */}
            </div>
        </div >
    )
}

export default Compare
