
import React, { useState } from 'react'
import { Button, Flex } from 'antd'
import { Col, Row, Input, Skeleton, Tag } from 'antd'
import CompareRow from './../components/CompareRow/index'
import axios from "axios"
import { nanoid } from "nanoid"

const { TextArea } = Input

// const apiUrl = 'https://localhost:7291/api/Compare'
const apiUrl = 'https://gptapi.tyinvoice.cn/api/Compare/CompareV2'

function Compare() {

    const [loading, setLoading] = useState(true)
    const [value00, setValue00] = useState('')
    const [value01, setValue01] = useState('')
    const [value02, setValue02] = useState('')
    const [value03, setValue03] = useState('')
    const [value04, setValue04] = useState('')
    const [value05, setValue05] = useState('')
    const [msgId, setMsgId] = useState('')


    const [diffs00, setDiffs00] = useState(['[]', '[]', '[]', '[]', '[]'])
    const [diffs01, setDiffs01] = useState('[]')
    const [diffs02, setDiffs02] = useState('[]')
    const [diffs03, setDiffs03] = useState('[]')
    const [diffs04, setDiffs04] = useState('[]')
    const [diffs05, setDiffs05] = useState('[]')

    const [result, setResult] = useState([0, 0, 0, 0, 0])

    const maxlength = 3500


    const customStyle = {
        marginBottom: '30px'
    }

    const dohttpPost = async () => {

        axios.post(apiUrl, {
            contents: [value00, value01, value02, value03, value04, value05],
            withDiffBlock: true
        })
            .then((res) => {
                setLoading(false)

                debugger
                console.log("对比结果为 ok：")
                console.log(res)
                for (var i = 0; i < res.data.length; ++i) {
                    var data = res.data[i]
                    if (i === 0) {
                        var strArray = data.split(",") // 将字符串按逗号分隔成子字符串
                        var numArray = strArray.map(Number) // 将子字符串数组转换为数字数组
                        setResult(numArray)
                        setDiffs00(['[]', '[]', '[]', '[]', '[]'])
                    }
                    else if (i === 1)
                        setDiffs01(data)
                    else if (i === 2)
                        setDiffs02(data)
                    else if (i === 3)
                        setDiffs03(data)
                    else if (i === 4)
                        setDiffs04(data)
                    else if (i === 5)
                        setDiffs05(data)
                    // else if (i >= 1 && i <= 5) {

                    //     if (i == 1)
                    //         setDiffs01(data)
                    //     var diffs_temp = [...diffs00]
                    //     diffs_temp[i - 1] = data
                    //     setDiffs00(diffs_temp)
                    // }
                }
                setMsgId(nanoid())

            })
            .catch((err) => {
                debugger
                console.log("对比结果为 err：")
                console.log(err)
            })
    }

    const showTag = (index) => {

        var val02 = ""
        if (index === 0) {
            val02 = value00
            return <>原始文本 字数为: <Tag >{val02.length}</Tag></>

        } else if (index === 1) {
            val02 = value01
        } else if (index === 2) {
            val02 = value02
        } else if (index === 3) {
            val02 = value03
        } else if (index === 4) {
            val02 = value04
        } else if (index === 5) {
            val02 = value05
        }


        var val = result[index - 1]

        var color = ''
        if (val > 80) {
            color = 'red'
        }
        if (val > 60) {
            color = 'yellow'
        }
        if (val > 40) {
            color = 'blue'
        }
        else {
            color = 'green'
        }
        return <>核对0{index} 相似度为: <Tag color={color}>{val}%</Tag>, 字数为: <Tag >{val02.length}</Tag></>
    }



    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // height: '100vh',
            minHeight: '100vh'
        }}>
            <div style={{
                width: '100%',
                padding: '30px 0',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
            }}>

                <Row>
                    <Col xs={3} sm={3} md={3} lg={1} xl={1} offset={1} >
                    </Col>
                    <Col xs={19} sm={19} md={19} lg={9} xl={9} >
                        <Flex gap="small" wrap="wrap">
                            <Button type="primary" onClick={() => {
                                setLoading(true)
                                dohttpPost()
                            }}>开始对比</Button>
                            <Button onClick={() => {
                                setLoading(true)
                                setValue00("")
                                setValue01("")
                                setValue02("")
                                setValue03("")
                                setValue04("")
                                setValue05("")
                                setDiffs00(['[]', '[]', '[]', '[]', '[]'])

                                setDiffs01('[]')
                                setDiffs02('[]')
                                setDiffs03('[]')
                                setDiffs04('[]')
                                setDiffs05('[]')

                                setMsgId("clear_" + nanoid())


                            }}>清空所有</Button>
                            {/* <Button type="link">去提问</Button> */}

                        </Flex>
                        {
                            !loading && <>
                                <p>{showTag(0)}</p>
                                <p>{showTag(1)}</p>
                                <p>{showTag(2)}</p>
                                <p>{showTag(3)}</p>
                                <p>{showTag(4)}</p>
                                <p>{showTag(5)}</p>
                                {/* <p>核对02 相似度为:&nbsp; {showTag(result[1], value02)}</p>
                                <p>核对03 相似度为:&nbsp; {showTag(result[2], value03)}</p>
                                <p>核对04 相似度为:&nbsp; {showTag(result[3], value04)}</p>
                                <p>核对05 相似度为:&nbsp; {showTag(result[4], value05)}</p> */}
                            </>
                        }
                        <Skeleton active loading={loading} />
                    </Col>

                    <Col xs={3} sm={3} md={3} lg={1} xl={1} offset={1} >
                        原始文本
                    </Col>
                    <Col xs={19} sm={19} md={19} lg={9} xl={9}>
                        <TextArea style={customStyle} showCount maxLength={maxlength} rows={11} value={value00}
                            onChange={(e) => setValue00(e.target.value)} />
                    </Col>

                </Row>

                <CompareRow
                    title01={"核对01"}
                    title02={"提示01"}
                    msgId={msgId}
                    loading={loading}
                    value={value01}
                    diffs={diffs01}
                    rate={result[0]}
                    onValueChange={(value) => { setValue01(value) }}
                ></CompareRow>

                <CompareRow
                    title01={"核对02"}
                    title02={"提示02"}
                    msgId={msgId}
                    loading={loading}
                    value={value02}
                    diffs={diffs02}
                    rate={result[1]}
                    onValueChange={(value) => { setValue02(value) }}
                ></CompareRow>

                <CompareRow
                    title01={"核对03"}
                    title02={"提示03"}
                    msgId={msgId}
                    loading={loading}
                    value={value03}
                    diffs={diffs03}
                    rate={result[2]}
                    onValueChange={(value) => { setValue03(value) }}
                ></CompareRow>

                <CompareRow
                    title01={"核对04"}
                    title02={"提示04"}
                    msgId={msgId}
                    loading={loading}
                    value={value04}
                    diffs={diffs04}
                    rate={result[3]}
                    onValueChange={(value) => { setValue04(value) }}
                ></CompareRow>

                <CompareRow
                    title01={"核对05"}
                    title02={"提示05"}
                    msgId={msgId}
                    loading={loading}
                    value={value05}
                    diffs={diffs05}
                    rate={result[4]}
                    onValueChange={(value) => { setValue05(value) }}
                ></CompareRow>



            </div>
        </div >
    )
}

export default Compare
